<template>
    <!-- 停用 启用 -->
    <el-dialog
        top="12%"
        :visible="dialogVisible"
        width="458px"
        :modal-append-to-body="false"
        class="pop-dialog aops-activity-status-dialog"
        @close="hide"
    >
        <div class="aop-confirm-info">
            确认<span class="aop-status-text">{{activity.activity_status === 1 ? '停用' : '启用'}}</span>{{activity.activity_title}}拉新活动？
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="confirm-button" :loading="btnLoading" @click="changeStatus" >确认</el-button>
            <el-button class="cancel-button" @click="hide">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import api from '@/api/promotions';

export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        activity: {
            type: Object,
            default: () => {}
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            btnLoading: false
        };
    },
    methods: {
        /**
         * 点击确定按钮
        */
        changeStatus() {
            this.btnLoading = true;
            const data = {
                // activity_id: this.activity.activity_id,
                activity_status: this.activity.activity_status === 1 ? 2 : 1
            };
            api.activityChangeStatus(data).then((res) => {
                this.handleApiRes(res);
            }).catch((error) => {
                this.$$error(error);
                this.btnLoading = false;
            });
        },
        /**
         * 处理接口返回
         * @param {Object} res 结果
        */
        handleApiRes(res) {
            if (res.data.code === 10200) {
                this.hide();
                this.$$success(res.data.message);
                this.$emit('onHandleQuery');
            } else {
                this.$$error(res.data.message);
            }
            this.btnLoading = false;
        },
        /**
         * 关闭弹窗
        */
        hide() {
            this.$emit('update:dialogVisible', false);
        }
    }
};
</script>
<style lang="less">
@import "./status_dialog.less";
</style>
