<template>
    <div v-if="hasResource('activity_management')" class="aops-pull-new-manage">
        <div>
            <el-button class="aop-pull-new-btn" type="primary" @click="handleAdd">活动介绍</el-button>
            <el-button
                class="aop-pull-new-btn"
                @click="handleRule"
            >规则设置</el-button>
            <el-button class="aop-pull-new-btn" @click="handlePermissClick">活动权限</el-button>
            <el-button
                class="aop-pull-new-btn"
                @click="handleChangeStatus"
            >{{data.activity_status === 1 ? '停用': '启用'}}活动</el-button>
        </div>
        <div v-loading="dataLoading" class="aop-pull-activity" >
            <div v-if="data.activity_title" class="aop-activity">
                <div class="aop-activity-title">
                    <div class="aop-activity-name">
                        <span class="aop-activity-name-text">{{data.activity_title}}</span>
                        <span
                            class="aop-activity-status"
                            :class="{'aop-status-primary': data.activity_status === 1}"
                        >{{data.activity_status | statusFilter}}</span>
                    </div>
                    <el-button class="aop-pull-new-btn" @click="handlePreview">预览</el-button>
                </div>
                <div class="aop-activity-cont">
                    <img
                        class="aop-activity-img"
                        :src="data.activity_banner ? data.activity_banner : defaultImg"
                        alt="活动图片"
                    />
                    <div class="aop-activity-info">
                        <div
                            v-for="item in ruleInfo"
                            :key="item.val"
                            class="aop-activity-info-item"
                        >
                            <div class="aop-activity-info-title">{{item.name}}</div>
                            <div class="aop-info-box">
                                <div class="aop-info-item">
                                    <img class="aop-info-icon" src="./image/Vector.png" alt="icon" />达成条件
                                    <span class="aop-info-value">
                                        {{data.activity_rule ? `每${data.activity_rule[item.val].success_num}人` : '-'}}
                                    </span>
                                </div>
                                <div class="aop-info-item">
                                    <img class="aop-info-icon" src="./image/points.png" alt="icon" />邀请者
                                    <span class="aop-info-value">
                                        {{inviterVal(item.val, 'inviter')}}
                                    </span>
                                </div>
                                <div class="aop-info-item">
                                    <img class="aop-info-icon" src="./image/points.png" alt="icon" />被邀请者
                                    <span class="aop-info-value">{{inviterVal(item.val, 'invitee')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <statusDialog
            :dialog-visible.sync="statusDialogVisible"
            :activity="data"
            @onHandleQuery="getActivity"
        />
    </div>
</template>
<script>
import env from 'env';
import Api from '@/api/promotions';
import statusDialog from './status_dialog/status_dialog.vue';
import defaultImg from './image/activity-default.png';
import { ruleInfo, status } from './data';

export default {
    name: 'PullNewManage',
    components: { statusDialog },
    filters: {
        /**
         * 状态
         * @param {*} val 状态值
         * @returns {string}
         */
        statusFilter(val) {
            if (!val) { return ''; }
            return status.find((item) => item.value === val).text;
        }
    },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            defaultImg,
            data: {
                // activity_id: 0
            },
            ruleInfo,
            statusDialogVisible: false, // 停用/启用确认框
            dataLoading: false
        };
    },
    /**
     * created
    */
    created() {
        this.getActivity();
    },
    methods: {
        /**
         * 获取活动信息
         */
        getActivity() {
            this.dataLoading = true;
            Api.activityConfig().then((res) => {
                if (res.data.code === 10200) {
                    const resData = res.data.data?.items || [];
                    this.data = resData.length ? resData[0] : {};
                } else {
                    this.$$error(res.data.message);
                }
                this.dataLoading = false;
            }).catch(() => {
                this.dataLoading = false;
                this.data = {};
            });
        },
        /**
         * 规则
         * @param {*} type 注册/付费字段
         * @param {*} inviter inviter // 邀请/被邀请字段
         * @returns {string}
         */
        inviterVal(type, inviter) {
            if (this.data.activity_rule) {
                const subscription = this.data.activity_rule[type][`award_${inviter}_subscription`];
                const subscriptionText = (subscription || subscription === 0) ? `${subscription}天订阅` : '';
                const score = this.data.activity_rule[type][`award_${inviter}_score`];
                return subscriptionText || (score || score === 0) ? `${subscriptionText}+${score}积分` : '-';
            }
            return '';
        },
        /**
         * 点击活动介绍按钮，跳转活动介绍页面
         */
        handleAdd() {
            this.$router.push({
                path: '/promotions/activity-introduce'
            });
        },
        /**
         * 点击活动规则按钮
         */
        handleRule() {
            if (this.data.activity_status === 1) {
                this.$$warning('活动已启用，不可变更规则设置');
                return;
            }
            this.$router.push({
                path: '/promotions/activity-rule'
                // query: {
                //     id: this.data.activity_id
                // }
            });
        },
        /**
         * 点击活动规则按钮
         */
        handlePermissClick() {
            this.$router.push({
                path: '/promotions/activity-permission'
            });
        },
        /**
         * 点击停用/启用按钮
         */
        handleChangeStatus() {
            this.statusDialogVisible = true;
        },
        /**
         * 点击预览
        */
        handlePreview() {
            window.open(`${env.webCn}registration-view`);
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
